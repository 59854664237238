import React from 'react';
import Layout from '../../../components/layout';
import RegistrationFrom from '../../../components/registration-form/registration-from';

export default function RegisterUnido(props) {
  return (
    <Layout>
      <RegistrationFrom imageName='moiat-unido' imageAlt='moiat-unido' source='MOIAT - UNIDO' page='expo-pavilion' />
    </Layout>
  );
}
